import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { isUndefined } from 'lodash-es';
import { ChangeEvent, useRef } from 'react';
import { StyleObj } from '../../@types';
import { isNumberString, parseNumberWithDecimals } from '../../helpers';

const styles: StyleObj = {
  inputContainer: {
    '& .MuiInputBase-root ': {
      paddingRight: 0,
    },
  },
  stepperButton: {
    p: 0,
    mr: 0.5,
    '& svg': {
      fontSize: 22,
    },
  },
};

type NumberInputProps = {
  onChange?: (value: string) => void;
  allowNegative?: boolean;
  allowDecimals?: boolean;
  decimalPlaces?: number;
  min?: number;
  max?: number;
  withCustomButtons?: boolean;
  step?: number;
  value?: string;
} & Omit<TextFieldProps, 'onChange'>;

const NumberInput = ({
  onChange,
  allowNegative = false,
  allowDecimals = true,
  decimalPlaces = 2,
  min,
  max,
  withCustomButtons = false,
  step = 1,
  value = '',
  ...otherProps
}: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const decimals = allowDecimals ? decimalPlaces : 0;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (isNumberString(newValue) || newValue === '' || (allowNegative && newValue.startsWith('-'))) {
      const decimalValueLength = newValue?.split('.')?.[1]?.length || 0;
      if (decimalValueLength > decimals) return;
      if (!allowNegative && newValue.includes('-')) return;

      onChange?.(newValue);
    }

    const decimalValueLength = newValue?.split('.')?.[1]?.length || 0;
    if (decimalValueLength > decimals) return;
  };

  const handleBlur = () => {
    if (value === '') return;

    let newValue = parseNumberWithDecimals(value, decimals);
    const newRawValue = Number(newValue);

    if (newRawValue === 0) {
      newValue = '';
    }

    if (!isUndefined(min) && newRawValue < min) {
      newValue = parseNumberWithDecimals(min, decimals);
    }

    if (!isUndefined(max) && newRawValue > max) {
      newValue = parseNumberWithDecimals(max, decimals);
    }

    onChange?.(newValue);
  };

  const handleIncrement = () => {
    onChange?.(parseNumberWithDecimals(Number(value) + step, decimals));
    inputRef.current?.focus();
  };

  const handleDecrement = () => {
    onChange?.(parseNumberWithDecimals(Number(value) - step, decimals));
    inputRef.current?.focus();
  };

  const isAboveMax = !isUndefined(max) && Number(value) + step > max;
  const isBelowMin = !isUndefined(min) && Number(value) - step < min;

  return (
    <TextField
      inputRef={inputRef}
      onChange={handleInputChange}
      onBlur={handleBlur}
      value={value}
      autoComplete='off'
      sx={styles.inputContainer}
      InputProps={{
        endAdornment: withCustomButtons && (
          <Stack>
            <IconButton onClick={handleIncrement} disabled={isAboveMax} sx={styles.stepperButton}>
              <ExpandLess />
            </IconButton>
            <IconButton onClick={handleDecrement} disabled={isBelowMin} sx={styles.stepperButton}>
              <ExpandMore />
            </IconButton>
          </Stack>
        ),
      }}
      inputProps={{
        inputMode: 'numeric',
      }}
      {...otherProps}
    />
  );
};

export default NumberInput;
