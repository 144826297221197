import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyleObj } from '../../@types';
import useBreakpoints from '../../hooks/useBreakpoints';
import Logo from '../icons/Logo';
import HeaderTabNavigation from './HeaderTabNavigation';
import AccountMenu from './menus/AccountMenu';
import { HEADER_MENU_ALLOWED_ROUTES } from '../../constants';

type HeaderProps = {
  toggleDrawerVisibility?: (isOpen: boolean) => void;
};

export const HEADER_HEIGHT = 64;

const styles: StyleObj = {
  appBar: {
    height: HEADER_HEIGHT,
    backgroundColor: 'primary.600',
  },
  toolbar: {
    minHeight: HEADER_HEIGHT,
    justifyContent: 'space-between',
    px: { xs: 1, md: 2 },
  },
  logo: {
    borderRadius: 2,
  },
  menuIcon: {
    color: 'info.main',
  },
};

const Header = ({ toggleDrawerVisibility }: HeaderProps) => {
  const { isMobile } = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToHomePage = () => {
    navigate('/');
  };

  const openDrawer = () => {
    toggleDrawerVisibility?.(true);
  };

  return (
    <AppBar position='fixed' elevation={0} sx={styles.appBar}>
      <Toolbar sx={styles.toolbar}>
        {isMobile ? (
          HEADER_MENU_ALLOWED_ROUTES.some((route) => location.pathname.includes(route)) ? (
            <IconButton onClick={openDrawer}>
              <MenuIcon sx={styles.menuIcon} />
            </IconButton>
          ) : null
        ) : (
          <IconButton onClick={navigateToHomePage} sx={styles.logo}>
            <Logo />
          </IconButton>
        )}
        <HeaderTabNavigation />
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
