import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Typography } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { useLocation } from 'react-router-dom';
import useLocalization from '../../hooks/useLocalization';
import { TFunction } from 'i18next';

const getBettingHeaderLabels = (t: TFunction) => [
  { title: t('ticketType'), subtitle: t('ticketId') },
  { title: t('dateAndTime') },
  { title: t('stake') },
  { title: t('possibleReturn') },
  { title: t('return') },
];

const getCasinoHeaderLabels = (t: TFunction) => [
  { title: t('transaction') },
  { title: t('dateAndTime') },
  { title: t('amount') },
  { title: t('previousBalance') },
  { title: t('newBalance') },
];

type HeaderLabels = { title: string; subtitle?: string }[];

const BetHistoryTableHead = () => {
  const location = useLocation();
  const { t } = useLocalization();
  const isCasino = location.pathname.includes('casino');
  const headerLabels: HeaderLabels = isCasino ? getCasinoHeaderLabels(t) : getBettingHeaderLabels(t);

  return (
    <TableHead>
      <TableRow>
        {headerLabels.map((label) => (
          <TableCell key={label.title}>
            <Stack alignItems='center'>
              <Typography variant='body2' fontWeight={600}>
                {label.title}
              </Typography>
              <Typography variant='body2'>{label.subtitle}</Typography>
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BetHistoryTableHead;
