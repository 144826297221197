import { Box, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SanityImage, StyleObj } from '../../@types';
import CMSTopContent from '../organisms/CMSTopContent';
import { getSanityImage, useSanity } from '../../hooks/useSanity';
import { LoaderContainer } from '../atoms/LoaderContainer';
import { useIFrameData } from '../../hooks/useIFrameData';
import { useUserSettings } from '../../queries';

const styles: StyleObj = {
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  innerWrapper: {
    maxWidth: '1400px',
  },
};

const CasinoPage = () => {
  const { data: backgroundImage } = useSanity<{ image: SanityImage }>('CasinoBackgroundImage');
  const theme = useTheme();

  const [ready, setReady] = useState(false);
  const { isMobileViewGameOpen, url } = useIFrameData();

  const topContentWrapperRef = useRef<HTMLDivElement>();

  const src = getSanityImage(backgroundImage?.[0]?.image);

  const { data: userData } = useUserSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendMessageToIframe = useCallback(
    (message: { language: string }) => {
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage(message, url);
      }
    },
    [url]
  );

  const handleIframeLoad = () => {
    setReady(true);
    sendMessageToIframe({ language: userData?.language ?? 'en' });
  };

  useEffect(() => {
    if (url) sendMessageToIframe({ language: userData?.language ?? 'en' });
  }, [sendMessageToIframe, url, userData?.language]);

  return (
    <Box
      width={1}
      height={1}
      sx={{
        ...styles.container,
        backgroundImage: `url("${src}")`,
        minHeight: isMobileViewGameOpen ? 'auto' : '1550px',
      }}
    >
      <Box width={1} sx={{ ...styles.innerWrapper, minHeight: isMobileViewGameOpen ? 'auto' : '800px' }}>
        {!isMobileViewGameOpen && (
          <Box ref={topContentWrapperRef}>
            <CMSTopContent scope='casino' />
          </Box>
        )}
        <Box
          width={1}
          sx={{
            height: `calc(100% - ${topContentWrapperRef.current?.clientHeight ?? 0}px)`,
            backgroundColor: '#fff',
          }}
        >
          {!ready && <LoaderContainer />}
          <iframe
            ref={iframeRef}
            title='casino'
            src={url}
            onLoad={handleIframeLoad}
            style={{
              border: 'none',
              width: '100%',
              height: isMobileViewGameOpen ? '100vh' : '100%',
              position: isMobileViewGameOpen ? 'absolute' : 'relative',
              top: 0,
              overflow: 'hidden',
              backgroundColor: theme.palette.background.default,
            }}
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default CasinoPage;
