import { Button, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { memo } from 'react';
import { StakeErrors, StyleObj } from '../../@types';
import { CURRENCY } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { OddsChangeStatus } from '../../helpers/betslipMessageParser';
import { useUserSettings } from '../../queries';
import useLocalization from '../../hooks/useLocalization';

const styles: StyleObj = {
  placeBetButton: {
    borderRadius: 0,
    ':disabled': {
      '& span': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
  updateStakeButton: {
    height: '100%',
    backgroundColor: 'primary.500',
    borderRadius: 0,
  },
  acceptChangesButton: {
    backgroundColor: 'info.600',
    color: 'neutral.800',
    ':hover': {
      backgroundColor: 'info.500',
    },
    borderRadius: 0,
  },
};

type Props = {
  stakeErrors: StakeErrors;
  totalStakeAmount: number;
  updateStakes: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled: boolean;
  disabledSystems: string[];
};

const BetslipPlaceBetButton = ({ stakeErrors, totalStakeAmount, updateStakes, isDisabled, disabledSystems }: Props) => {
  const { data: userSettings } = useUserSettings();
  const { bets, errors, clearErrors } = useBetslip();
  const { t } = useLocalization();

  const oddsChanges = userSettings?.oddsChanges ?? 'none';

  const acceptChanges = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    clearErrors();
  };

  const hasOnlySystemErrors = Object.keys(stakeErrors).length === disabledSystems.length;

  if (!isEmpty(stakeErrors)) {
    return (
      <Button
        disableRipple
        fullWidth
        sx={styles.updateStakeButton}
        onClick={updateStakes}
        disabled={hasOnlySystemErrors}
      >
        <Stack direction='column'>
          <Typography variant='h5'>{t('updateStakes')}</Typography>
        </Stack>
      </Button>
    );
  }

  const hasDecreasedOdds = bets.some((bet) => bet.oddsChange === OddsChangeStatus.decreased);
  if (!!errors.length && (oddsChanges === 'none' || (hasDecreasedOdds && oddsChanges === 'rising'))) {
    return (
      <Button disableRipple fullWidth sx={styles.acceptChangesButton} onClick={acceptChanges}>
        <Typography variant='h5'>{t('acceptChanges')}</Typography>
      </Button>
    );
  }

  if ((!!errors.length && oddsChanges === 'all') || (oddsChanges === 'rising' && !hasDecreasedOdds)) {
    return (
      <Button disableRipple fullWidth type='submit' sx={styles.placeBetButton} disabled={isDisabled}>
        <Typography variant='h5'>
          {t('placeBet')}{' '}
          <Typography variant='h5' component='span' color='info.main'>
            {CURRENCY.symbol}
            {totalStakeAmount.toFixed(2)}
          </Typography>
        </Typography>
      </Button>
    );
  }

  return (
    <Button disableRipple fullWidth type='submit' sx={styles.placeBetButton} disabled={isDisabled}>
      <Typography variant='h5'>
        {t('placeBet')}{' '}
        <Typography variant='h5' component='span' color='info.main'>
          {CURRENCY.symbol}
          {totalStakeAmount.toFixed(2)}
        </Typography>
      </Typography>
    </Button>
  );
};

export default memo(BetslipPlaceBetButton);
