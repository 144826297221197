import React, { useCallback, useEffect, useState } from 'react';
import { use } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { useUserSettings } from '../queries';
import { Backdrop } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/sq';
import { LanguageType } from '../@types';

interface Locale {
  code: LanguageType;
  label: string;
  englishLabel?: string;
  shortLabel?: string;
}

//locales should be received from CRM configuration once multitenancy is completed
const locales: Locale[] = [
  {
    code: 'en',
    label: 'English (US)',
    shortLabel: 'EN',
  },
  {
    code: 'sq',
    label: 'Shqip',
    englishLabel: 'Albanian',
    shortLabel: 'SQ',
  },
];

const sourcePath = '/locales/{{lng}}/{{ns}}.json'; // Translations are located in public file until we complete all translations
const defaultLng = 'en';

const setUp = (lng: string) => {
  use(Backend)
    .use(initReactI18next)
    .init(
      {
        fallbackLng: defaultLng,
        load: 'currentOnly',
        ns: ['common', 'sports'],
        defaultNS: 'common',
        lng,
        debug: false,
        backend: {
          loadPath: sourcePath,
        },
      },
      (err) => {
        /*eslint-disable-next-line no-console*/
        if (err) return console.error('something went wrong loading', err);
      }
    );
};

interface Props {
  children: React.ReactElement;
}

export const LanguageInstanceProvider = ({ children }: Props): React.ReactElement => {
  const { data: userSettings } = useUserSettings();
  const [isInitialized, setIsInitialized] = useState(false);

  const getDefaultLanguage = useCallback(() => {
    const sourceLng = userSettings?.language;
    const matchedLng = locales?.find((lng) => lng.code.toLowerCase() === sourceLng?.toLowerCase());
    return matchedLng?.code ?? sourceLng ?? defaultLng;
  }, [userSettings?.language]);

  const defaultLanguage = getDefaultLanguage();

  useEffect(() => {
    setUp(defaultLanguage);
    dayjs.locale(defaultLanguage);

    setIsInitialized(true);
  }, [defaultLanguage]);

  if (!isInitialized) {
    return <Backdrop open={isInitialized} />;
  }

  return children;
};
