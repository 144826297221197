import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';
import { useCallback } from 'react';

export type LabelledObject = {
  label: string;
  [key: string]: unknown;
};

const useLocalization = () => {
  const { t, ...useTranslationContext } = useTranslation();

  const getLocalizedConfig = useCallback(
    <T extends LabelledObject>(config: T[], options?: TOptions) => {
      return config.map((tab) => ({
        ...tab,
        label: t(tab.label, options) ?? '',
      }));
    },
    [t]
  );

  const getLocalizedSportName = useCallback(
    (sportName?: string) => {
      return sportName ? t(sportName, { ns: 'sports' }) : '';
    },
    [t]
  );

  return { t, getLocalizedConfig, getLocalizedSportName, ...useTranslationContext };
};

export default useLocalization;
