import { IconButton, Stack, Typography } from '@mui/material';
import { calculateTotalOddsForNormalBettingSlip } from 'sportsbook-shared-module';
import { StyleObj } from '../../@types';
import { BETSLIP_WIDTH } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import BetslipBetCount from '../atoms/BetslipBetCount';
import BetslipInfoMessage from '../atoms/BetslipInfoMessage';
import { ExpandLess } from '@mui/icons-material';
import { getLocalizedBettingTypeFromHitCount } from '../../helpers';
import useLocalization from '../../hooks/useLocalization';

const makeStyles = (open: boolean): StyleObj => ({
  container: {
    background: 'white',
    borderRadius: {
      xs: 0,
      sm: '4px 4px 0 0',
    },
    position: 'fixed',
    width: {
      xs: '100%',
      sm: BETSLIP_WIDTH,
    },
    bottom: {
      xs: 0,
      md: 32,
    },
    left: {
      xs: 0,
      sm: `calc(50% - ${BETSLIP_WIDTH / 2}px)`,
    },
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    display: open ? 'none' : 'flex',
  },
  button: {
    borderRadius: {
      xs: 0,
      sm: '4px 4px 0 0',
    },
    px: 2,
  },
  iconBtn: {
    padding: 0,
  },
  betCount: {
    flex: 1,
  },
});

type OpenBetslipButtonProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const OpenBetslipButton = ({ open, setOpen }: OpenBetslipButtonProps) => {
  const { bets, infoMessages, uniqueEventCount, isWaysTicket } = useBetslip();
  const styles = makeStyles(open);

  const { t } = useLocalization();

  const preparedBets = bets.map((bet) => {
    return {
      ...bet,
      marketTypeId: bet.marketType.id,
      marketTypeCombiningIds: bet.marketType.marketTypeCombiningIds,
      singlesStakeAmount: Number(bet.singlesStakeAmount),
    };
  });

  const totalOdds = calculateTotalOddsForNormalBettingSlip(preparedBets);

  const localizedBettingType = getLocalizedBettingTypeFromHitCount(t, uniqueEventCount);

  return (
    <Stack sx={styles.container}>
      {Array.from(infoMessages)?.map((message) => <BetslipInfoMessage key={message} message={message} />)}
      <Stack direction='row' alignItems='center' spacing={1} sx={styles.button} onClick={() => setOpen(!open)}>
        <BetslipBetCount numberOfBets={bets.length} sx={styles.betCount} />
        {!isWaysTicket && (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant='body3' fontWeight={600} color='neutral.500'>
              1x {localizedBettingType}
            </Typography>
            <Typography variant='h6' fontWeight={500} fontSize={16} color='primary'>
              {totalOdds.toFixed(2)}
            </Typography>
          </Stack>
        )}
        <IconButton sx={styles.iconBtn}>
          <ExpandLess />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default OpenBetslipButton;
