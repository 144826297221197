import { Box, Typography, keyframes } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { JackpotPool } from '../../@types';
import { CURRENCY, QUERY_KEYS } from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { useJackpotPools } from '../../queries';
import JackpotIcon from '../icons/JackpotIcon';
import isEmpty from 'lodash-es/isEmpty';
import useLocalization from '../../hooks/useLocalization';

const SLIDE_DURATION = 1.5;
const SLIDE_ITERATION_COUNT = 4;
const SLIDE_BOUNCE_DURATION = 4;

const AUTO_HIDE_DURATION = 10;

const ANIMATION_DURATION = SLIDE_DURATION * SLIDE_ITERATION_COUNT + SLIDE_BOUNCE_DURATION + 1;
const VISIBILITY_DURATION = ANIMATION_DURATION + AUTO_HIDE_DURATION;

const generateSlideAnimation = (finalIteration: boolean) => keyframes`
  0% {
    transform: translateY(0%);
  }
  ${finalIteration ? '80%' : '100%'} {
    transform: translateY(-310%);
  }
  100% {
    transform: translateY(-300%);
  }
`;

const slide = generateSlideAnimation(false);
const slideBounce = generateSlideAnimation(true);

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const styles = {
  borderBox: {
    border: '10px solid #ffd900',
    borderRadius: 4,
    position: 'absolute',
    height: '25vh',
    width: {
      xs: 225,
      md: 275,
    },
    zIndex: 20,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    m: 'auto',
    boxShadow: '0 0 100px rgba(255, 165, 0, 0.8)',
  },
  animationContainer: {
    width: {
      xs: 225,
      md: 275,
    },
    position: 'absolute',
    borderRadius: 4,
    height: '75vh',
    zIndex: 10,
    m: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.8)',
    overflowY: 'hidden',
    boxShadow: '0 0 100px rgba(0, 0, 0, 0.8)',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '150vh',
  },
  animationBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 3,
    flex: 1,
    width: '100%',
    background: 'rgba(0,0,0,0.8)',
  },
  jackpotGif: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    m: 'auto',
    zIndex: 5,
    width: 'clamp(300px, 100%, 1000px)',
  },
  opacityBoxTop: {
    border: 'none',
    top: '-50vh',
    zIndex: 25,
    background: 'rgba(0,0,0,0.75)',
    boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
  },
  opacityBoxBottom: {
    border: 'none',
    top: '50vh',
    zIndex: 25,
    background: 'rgba(0,0,0,0.75)',
    boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
  },
  amount: {
    color: '#ffd900',
    ml: 1,
  },
};

type JackpotAnimationProps = {
  winningJackpotPool: JackpotPool;
  clearJackpot: () => void;
};

const JackpotAnimation = ({ winningJackpotPool, clearJackpot }: JackpotAnimationProps) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const [hideAnimation, setHideAnimation] = useState(false);
  const [initialJackpots, setInitialJackpots] = useState<JackpotPool[] | null>(null);

  const invalidateData = useInvalidateQuery();
  const { data: jackpotData } = useJackpotPools();
  const { t } = useLocalization();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationFinished(true);
      enqueueSnackbar(
        t('congratulationsJackpot', {
          jackpot: winningJackpotPool.name,
          amount: winningJackpotPool.amount.toFixed(2),
          currency: CURRENCY.symbol,
        }),
        {
          variant: 'success',
          autoHideDuration: AUTO_HIDE_DURATION * 1000,
          preventDuplicate: true,
          disableWindowBlurListener: true,
        }
      );
      invalidateData([QUERY_KEYS.jackpots, QUERY_KEYS.balance]);
    }, ANIMATION_DURATION * 1000);

    return () => clearTimeout(timer);
  }, [invalidateData, t, winningJackpotPool.amount, winningJackpotPool.name]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHideAnimation(true);
      clearJackpot();
    }, VISIBILITY_DURATION * 1000);

    return () => clearTimeout(timer);
  }, [clearJackpot]);

  useEffect(() => {
    if (jackpotData && !initialJackpots) {
      setInitialJackpots(jackpotData);
    }
  }, [initialJackpots, jackpotData, setInitialJackpots]);

  if (!initialJackpots) return null;
  if (hideAnimation) return null;

  const sortJackpotPools = () => {
    let winningJackpot = null;
    const nonWinningJackpots = [];

    for (const pool of initialJackpots) {
      if (pool.name === winningJackpotPool.name) {
        winningJackpot = { ...pool, amount: winningJackpotPool?.amount };
      } else {
        nonWinningJackpots.push(pool);
      }
    }

    if (!winningJackpot) return [];

    // The animation will always finish on the middle container, so we need to place the winning pool
    return [nonWinningJackpots[0], winningJackpot, nonWinningJackpots[1]];
  };

  const sortedJackpots = sortJackpotPools();

  if (isEmpty(sortedJackpots)) return null;

  // Double the array so we get the wrap-around effect
  const jackpotAnimationContainerItems = [...sortedJackpots, ...sortedJackpots];

  return (
    <>
      <Box
        sx={{
          ...styles.borderBox,
          ...styles.opacityBoxTop,
        }}
      />
      <Box sx={styles.borderBox} />
      <Box
        sx={{
          ...styles.borderBox,
          ...styles.opacityBoxBottom,
        }}
      />
      <Box sx={styles.animationContainer}>
        <Box sx={styles.innerContainer}>
          {jackpotAnimationContainerItems.map((pool, index) => (
            <SingleJackpotContainer jackpotPool={pool} key={pool.id} id={index % 3} />
          ))}
        </Box>
      </Box>
      {animationFinished && <Box sx={styles.jackpotGif} component='img' loading='lazy' src='/jackpot-won.gif' />}
    </>
  );
};

export default JackpotAnimation;

type SingleJackpotProps = {
  jackpotPool: JackpotPool;
  id: number;
};

const SingleJackpotContainer = ({ jackpotPool, id }: SingleJackpotProps) => {
  const [animation, setAnimation] = useState(slide);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setAnimation(slideBounce);
      },
      SLIDE_ITERATION_COUNT * SLIDE_DURATION * 1000
    );

    return () => clearTimeout(timer);
  }, []);

  const animationDuration = animation === slide ? `${SLIDE_DURATION}s` : `${SLIDE_BOUNCE_DURATION}s`;
  const animationTimingFunction = animation === slide ? 'linear' : 'ease-out';
  const animationIterationCount = animation === slide ? SLIDE_ITERATION_COUNT : 1;

  return (
    <Box
      sx={{
        ...styles.animationBox,
        animation: `${animation} ${animationDuration} ${animationTimingFunction} ${animationIterationCount}`,
      }}
    >
      <Box
        sx={{ animation: id === 1 ? `${spin} 1s linear infinite` : 'none', animationDelay: `${ANIMATION_DURATION}s` }}
      >
        <JackpotIcon type={jackpotPool.icon} size='large' />
      </Box>
      <Typography variant='h4' fontWeight={700} textAlign='center'>
        {jackpotPool.name}:
        <Typography variant='h2' sx={styles.amount} component='span'>
          {CURRENCY.symbol}
          {jackpotPool.amount.toFixed(2)}
        </Typography>
      </Typography>
    </Box>
  );
};
