import { BetslipStatus } from '../../@types';
import { Stack, Typography } from '@mui/material';
import { CURRENCY, getBetHistoryActionCellMap } from '../../constants';
import useLocalization from '../../hooks/useLocalization';

const HistoryStatusCell = ({ status, winnings }: { status: BetslipStatus; winnings: number | null }) => {
  const { t } = useLocalization();

  const BET_HISTORY_ACTION_CELL_MAP = getBetHistoryActionCellMap(t);

  return (
    <Stack alignItems='center'>
      {(status === 'won' || status === 'cashout') && (
        <Typography variant='body2' fontWeight={600}>
          {CURRENCY.symbol}
          {winnings?.toFixed(2)}
        </Typography>
      )}
      <Typography color={BET_HISTORY_ACTION_CELL_MAP[status].color}>
        {BET_HISTORY_ACTION_CELL_MAP[status].text}
      </Typography>
    </Stack>
  );
};

export default HistoryStatusCell;
