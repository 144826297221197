import { createClient } from '@sanity/client';
import dayjs from 'dayjs';
import imageUrlBuilder from '@sanity/image-url';
import { useQuery } from '@tanstack/react-query';
import { SanityImage } from '../@types';

export type Content =
  | 'PromotionalTopContent'
  | 'PromotionalSideContent'
  | 'PortableTextContent'
  | 'CasinoPromotionalTopContent'
  | 'CasinoBackgroundImage'
  | 'Logo';

const QUERY_KEY_RECORD: Record<Content, string> = {
  PromotionalTopContent: 'top-data',
  PromotionalSideContent: 'side-data',
  PortableTextContent: 'footer-data',
  CasinoPromotionalTopContent: 'top-data-casino',
  CasinoBackgroundImage: 'bg-image-casino',
  Logo: 'logo',
};

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: true,
  apiVersion: dayjs().format('YYYY-MM-DD'),
});

export const getSanityImage = (image?: SanityImage) => {
  if (!image?.asset?._ref) {
    return '';
  }
  const builder = imageUrlBuilder(client);
  const img = builder.image(image?.asset?._ref);
  return img?.url();
};

export const useSanity = <T>(contentType: Content, pathname?: string) => {
  const type = `_type=="${contentType}"`;
  const path = pathname ? `&& path == "${pathname?.split('/')[2]}"` : '';

  return useQuery({
    queryKey: [QUERY_KEY_RECORD[contentType], pathname || ''],
    queryFn: (): Promise<T[]> => client.fetch(`*[${type}${path}]`).then((value) => value),
  });
};
