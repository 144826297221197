import { DrawerContentConfig, DrawerContentProps, StyleObj } from '../../@types';
import { ListItem, ListItemButton, ListItemIcon, Typography, List } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import SidebarAccordion from '../molecules/SidebarAccordion';
import { getAccountDrawerConfig } from '../../constants';
import useLocalization from '../../hooks/useLocalization';

const styles: StyleObj = {
  listItem: {
    p: 0,
    ':hover': {
      backgroundColor: (theme) => theme.palette.primary.main,
    },
    '& .MuiButtonBase-root': {
      px: 3,
      py: 2,
    },
    '& .MuiSvgIcon-root': {
      fill: (theme) => theme.palette.text.primary,
    },
  },
  listItemButton: {
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
  divider: {
    borderColor: (theme) => theme.palette.primary[500],
    mb: 1,
    mx: 3,
  },
};

const AccountDrawerContent = ({ toggleVisibility }: DrawerContentProps) => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const navigateTo = (path: string) => {
    navigate(path);
    toggleVisibility(false);
  };

  const ACCOUNT_DRAWER_CONFIG = getAccountDrawerConfig(t);

  return (
    <List>
      <ListItem sx={styles.listItem}>
        <ListItemButton
          disableRipple
          sx={styles.listItemButton}
          onClick={() => navigateTo('my-account')}
          disableGutters
        >
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <Typography variant='h3'>My Account</Typography>
        </ListItemButton>
      </ListItem>
      <Divider variant='middle' component='li' sx={styles.divider} />
      {ACCOUNT_DRAWER_CONFIG.map((item: DrawerContentConfig) => (
        <SidebarAccordion item={item} navigateTo={navigateTo} key={`account-sidebar-${item.text}`} />
      ))}
    </List>
  );
};

export default AccountDrawerContent;
