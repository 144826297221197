import { ReactElement } from 'react';
import { MenuItemType, TabConfigItem } from '../@types';

export type AccountMenuItem = {
  label?: string;
  value: string;
  icon?: ReactElement;
  sectionLabel?: string;
  to?: string;
  children?: MenuItemType[];
};

export type AccountMenuTab = 'my-account' | 'preferences';

export const ACCOUNT_TABS: TabConfigItem<AccountMenuTab>[] = [
  {
    label: 'account',
    value: 'my-account',
  },
  {
    label: 'preferences',
    value: 'preferences',
  },
];
